import { createPortal } from 'react-dom';
import { FCWithChildren } from '../../../types/FCWithChildren';

type TopNavPortalProps = {
  elementId?: string;
};

const TopNavPortal: FCWithChildren<TopNavPortalProps> = ({ children, elementId }) => {
  const mount = document.getElementById(elementId ?? 'portal-top-nav');
  if (!mount) {
    return null;
  }
  return createPortal(children, mount);
};

export default TopNavPortal;
